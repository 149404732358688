<template>
  <div class="modal modal-md fade" id="followup-modal" tabindex="-1" aria-labelledby="followupModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title" id="followupModalLabel">Thank you!</h5>
        </div>
        <div class="modal-body">
          <p v-if="!showFeedbackBox" class="text-center mb-0">Do you have any questions about this admission&nbsp;packet?</p>
          <p v-else class="mb-0 mb-3">Tell us about the questions you have regarding this admission.</p>
          <div v-if="showFeedbackBox || meetingRequested">
            <textarea v-model="feedback" class="form-control" rows="3" placeholder="What questions do you have?" style="height:80px;"></textarea>
            <hr/>
            <div class="d-flex align-items-center mt-3">
              <p class="mb-0 flex-fill">Would you like to request a followup meeting?</p>
              <div class="btn-group">
                <button type="button" class="btn btn-outline-secondary" :class="!meetingRequested ? 'active' : ''" @click="meetingRequested = false">No</button>
                <button type="button" class="btn btn-outline-secondary" :class="meetingRequested ? 'active' : ''" @click="meetingRequested = true">Yes</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div v-if="showFeedbackBox" class="d-flex gap-2 w-100">
            <button type="button" class="btn btn-secondary w-100" @click="showFeedbackBox = false" :disabled="loading">
              Nevermind
            </button>
            <button type="button" class="btn btn-primary w-100" @click="submitAndEmitDismissed" :disabled="loading">
              <span v-if="loading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
              Submit
            </button>
          </div>
          <div v-else class="d-flex gap-2 w-100">
            <button type="button" class="btn btn-secondary w-50" @click="showFeedbackBox = true" :disabled="loading">
              Yes
            </button>
            <button type="button" class="btn btn-primary w-50" @click="emit('dismissed')" :disabled="loading">
              <span v-if="loading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
              No Thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import axios from '@/axios'

const { signatureId } = defineProps<{
  signatureId: string
}>()

const emit = defineEmits<{
  (e: 'dismissed'): void
}>()

const showFeedbackBox = ref(false)
const meetingRequested = ref(false)

const feedback = ref<string>()
const loading = ref(false)

const submitAndEmitDismissed = async () => {
  loading.value = true
  await createFollowup()
  loading.value = false
  emit('dismissed')
}

const createFollowup = async () => {
  try {
    await axios.post(`/api/v1/followups`, {
      signature_id: signatureId,
      meeting_requested: meetingRequested.value,
      feedback: feedback.value,
    })
  } catch (error) {
    console.error(error)
  }
}
</script>