<template>
  <section class="sign w-100">
    <div v-if="loading" class="hellosign-loading">
      <i class="fa-regular fa-loader fa-spin fa-6x"></i>
    </div>
    <div v-else-if="errorMessage" class="d-flex h-full align-items-center">
      <GenericModal title="Notice" :body="errorMessage" buttonActionLabel="Back" :buttonAction="back" />
    </div>
    <div ref="hellosignContainer" id="hellosign-container" v-show="!loading" class="w-100"></div>
    <FollowupModal :signatureId="signatureId" @dismissed="nextAction" />
  </section>
</template>

<script setup lang="ts">
import HelloSign from 'hellosign-embedded'
import Cookies from 'js-cookie'
import { useAuthenticatedClient } from '@/mixins/app/useAuthenticatedClient'
import GenericModal from '../../components/app/GenericModal.vue'
import axios from '@/axios'
import { useRoute, useRouter } from 'vue-router'
import { computed, onBeforeMount, ref } from 'vue'
import { AxiosError } from 'axios'
import { useStore } from 'vuex'
import FollowupModal from './components/FollowupModal.vue'
import { Modal } from 'bootstrap'

const store = useStore()

const { client } = useAuthenticatedClient(store)
const { params } = useRoute()
const { back } = useRouter()

const loading = ref<boolean>(true)
const errorMessage = ref<string|null>(null)
const hellosignContainer = ref<HTMLElement>()
const redirectUrl = ref<string>()
const followupEnabled = ref<boolean>()

const signatureId = params.signatureId as string

const authToken = Cookies.get('access_token')

const embeddedSigningUrlEndpoint = computed(() => {
  if (authToken) {
    return `/api/v1/hello-sign/get-embedded-sign-url`
  } else {
    return `/admin/hello-sign/get-embedded-sign-url`
  }
})

const followupModal = computed(() => {
  return Modal.getOrCreateInstance('#followup-modal', {
    backdrop: 'static'
  })
})

interface EmbeddedSigningConfig {
  clientId: string
  testMode: boolean
}

interface EmbeddedSigningURLResponse {
  sign_url: string
  config: EmbeddedSigningConfig
  followups: boolean
}

const mountHelloSignApp = (embeddedSigningURLrl: string, config: EmbeddedSigningConfig) => {
  const helloSignClient = new HelloSign({
    allowCancel: true,
    clientId: config.clientId,
    container: hellosignContainer.value,
    debug: config.testMode,
    hideHeader: false,
    skipDomainVerification: config.testMode,
  })

  // Ready event
  helloSignClient.on(HelloSign.events.READY, () => {
    loading.value = false
  })

  // Message event
  helloSignClient.on(HelloSign.events.MESSAGE, async (data) => {
    switch (data.type) {
      // @ts-ignore
      case HelloSign.messages.USER_CANCEL_REQUEST: // User clicked the cancel button
      // @ts-ignore
      case HelloSign.messages.USER_DECLINE_REQUEST: // User clicked the decline button
        back()
        break
      // @ts-ignore
      case HelloSign.messages.USER_SIGN_REQUEST: // User signed the document
        updateSignatureRequest(data)
        break
    }
  })

  helloSignClient.open(embeddedSigningURLrl);
}

const updateSignatureRequest = async (data: object) => {
  loading.value = true
  try {
    const response = await axios.post<{
      redirect: string,
    }>('/sign/' + params.id, {
      // @ts-ignore
      signatureId: data.payload.signatureId
    })

    redirectUrl.value = response.data.redirect

    if (followupEnabled.value) {
      showFollowupModal()
    } else {
      nextAction()
    }
  } catch (error) {          
    console.log(error)
  }
  // loading.value = false
}

const getEmbeddedSigningURL = async () => {
  try {
    const response = await client.post<EmbeddedSigningURLResponse>(embeddedSigningUrlEndpoint.value, { id: signatureId })
    followupEnabled.value = response.data.followups
    mountHelloSignApp(response.data.sign_url, response.data.config)
  } catch (error) {
    if (error instanceof AxiosError) {
      errorMessage.value = error.response?.data.error
    } else if (error instanceof Error) {
      errorMessage.value = error.message
    }
  } finally {
    loading.value = false
  }
}

const showFollowupModal = () => {
  followupModal.value.show()
}

const nextAction = () => {
  followupModal.value.hide()
  if (redirectUrl.value) {
    window.location.href = redirectUrl.value
  }
}

onBeforeMount(() => {
  getEmbeddedSigningURL()
})
</script>

<style lang="scss" scoped>
#hellosign-container, .hellosign-loading {
  height: 100vh;
}

.hellosign-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
}
</style>
